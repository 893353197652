import React, { useState } from 'react'

import HoodCleaningServices from './hoodcleaning'

enum Categories {
  hoodCleaning,
}

const Links = [
  { category: Categories.hoodCleaning, label: 'Hood Cleaning' },
]

const Services = (): React.ReactElement => {
  const [curCategory, setCurCategory] = useState(Categories.hoodCleaning)

  const getContent = (): React.ReactElement => {
    switch (curCategory) {
      case Categories.hoodCleaning:
        return <HoodCleaningServices />
    }
  }

  return (
    <div id='services'>
      <div className='service-category-nav'>
        <div className='container scn-links-wrapper'>
          {Links.map(({ category, label }) => (
            <a
              className={`scn-link ${category === curCategory ? 'active' : ''}`}
              key={'scn-key-' + label.toLowerCase()}
              onClick={(): void => setCurCategory(category)}
            >
              {label}
            </a>
          ))}
        </div>
      </div>
      {getContent()}
    </div>
  )
}

export default Services
