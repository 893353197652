import React from 'react'

interface Props {
  className?: string
  children: any
}

const ServiceTextCard = (props: Props): React.ReactElement => {
  return (
    <div className={props.className}>
      <div className='card text-card'>
        <div className='card-content'>{props.children}</div>
      </div>
    </div>
  )
}

export default ServiceTextCard
