import React from 'react'

const placeholder = 'https://via.placeholder.com/300x200/000080/cccccc'

interface Props {
  title: string
  className?: string
  img?: string
  containBackground?: boolean
}

const ServiceImgCard = (props: Props): React.ReactElement => {
  return (
    <div className={props.className || 'col-12 col-md-6 col-lg-4'}>
      <div className='service-card card'>
        <div
          className={`img-wrapper ${
            props.containBackground ? 'background-contain' : ''
          }`}
          style={{ backgroundImage: `url('${props.img || placeholder}')` }}
        />
        <div className='card-content'>
          <h4>{props.title}</h4>
        </div>
      </div>
    </div>
  )
}

export default ServiceImgCard
