import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import InputWrapper from 'js/components/input'

const publicKey = 'khUUuv2zOZ2Sac4VW'
const templateId = 'template_42l0bnj'
const serviceId = 'service_1b0btly'

const EstimateRequest = (): React.ReactElement => {
  const [data, setData] = useState({
    fullName: '',
    companyName: '',
    phone: '',
    contactEmail: '',
    message: '',
  })
  const [submitted, setSubmitted] = useState(false)
  const [failed, setFailed] = useState(false)

  const changeHandler = ({ target }: any): void => {
    const name = target.name
    const value = target.value
    const newData = { ...data, [name]: value }
    setData(newData)
  }

  const submitForm = (e: any): void => {
    e.preventDefault()

    emailjs
      .send(
        serviceId,
        templateId,
        {
          full_name: data.fullName,
          company_name: data.companyName,
          phone: data.phone,
          reply_to: data.contactEmail,
          message: data.message,
        },
        publicKey
      )
      .then(() => {
        setSubmitted(true)
      })
      .catch(e => {
        setFailed(true)
        console.error('ERROR', e)
      })
  }

  // prettier-ignore
  const inputs = [
    { name: 'fullName', value: data.fullName, label: 'Full Name', required: true, autoComplete: 'name' },
    { name: 'companyName', value: data.companyName, label: 'Company Name', required: true, autoComplete: 'organization' },
    { name: 'contactEmail', value: data.contactEmail, label: 'Contact Email', type: 'email', required: true, autoComplete: 'email' },
    { name: 'phone', value: data.phone, label: 'Phone Number', type: 'phone', required: true, autoComplete: 'tel' },
    { name: 'message', value: data.message, label: 'How Can We Help?', type: 'textarea' },
  ];

  return (
    <div className='estimate-request'>
      <div className='card full-width'>
        <h1 className='container card-content text-center text-primary'>
          {submitted ? 'Your request has been submitted' : 'Request a Free Estimate'}
        </h1>
      </div>
      {submitted ? (
        <div className='card card-content'>
          <p style={{ maxWidth: '35rem', margin: 'auto' }}>
            Thank you for submitting your information. We will get back to you
            as soon as we can to get you the cleaning and services that you
            need.
          </p>
        </div>
      ) : (
        <div className='container'>
          <div className='estimate-request-form card'>
            {failed && (
              <div className='card-content text-center text-danger'>
                <b>Oops! Something went wrong.</b>
                <br />
                Please try submitting your request again or use the contact
                information in the footer.
              </div>
            )}
            <form className='card-content' onSubmit={submitForm}>
              {inputs.map(inputProps => {
                return (
                  <InputWrapper
                    key={'key-' + inputProps.name}
                    onChange={changeHandler}
                    {...inputProps}
                  />
                )
              })}
              <button className='btn w-100' type='submit'>
                Submit
              </button>
              <small>
                We service Northern Utah and Southern Idaho. We will respond to your inquiry as soon as possible to help you construct cleaning program that meets your restaurants needs.
              </small>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default EstimateRequest
