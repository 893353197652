import React, { useState } from 'react'

import Footer from 'js/components/footer'
import Header from 'js/components/header'
import AboutUs from 'js/containers/AboutUs'
import EstimateRequest from 'js/containers/EstimateRequest'
import Services from 'js/containers/Services'

import 'scss/index.scss'

export enum Pages {
  services,
  estimate,
  about,
}

const App = (): React.ReactElement => {
  const [page, setPage] = useState(Pages.services)

  const getPage = (): React.ReactElement => {
    switch (page) {
      case Pages.services:
        return <Services />
      case Pages.about:
        return <AboutUs />
      case Pages.estimate:
        return <EstimateRequest />
    }
  }

  return (
    <div id='app'>
      <Header setPage={setPage} currentPage={page} />
      <div id='maincontent'>{getPage()}</div>
      <Footer />
    </div>
  )
}

export default App
