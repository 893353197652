import React from 'react'

export interface InputWrapperProps {
  name: string
  value: any
  label: string
  type?: string
  required?: boolean
  onChange: (e: any) => void
}

const InputWrapper = ({
  type = 'text',
  ...props
}: InputWrapperProps): React.ReactElement => {
  const inputProps = {
    id: props.name,
    ...props,
  }
  if (type === 'textarea') {
    return (
      <div className='input-wrapper'>
        <label htmlFor={props.name}>
          {props.label}
          {props.required && '*'}
        </label>
        <textarea {...inputProps} />
      </div>
    )
  }
  return (
    <div className='input-wrapper'>
      <label htmlFor={props.name}>
        {props.label}
        {props.required && '*'}
      </label>
      <input {...inputProps} type={type} />
    </div>
  )
}

export default InputWrapper
