import React from 'react'

import Shawn from 'assets/Shawn.jpg'
import Dillan from 'assets/Dillan.jpg'

const AboutUs = (): React.ReactElement => {
  return (
    <div className='container about-us'>
      <div className='row'>
        <div className='col'>
          <div className='bio card card-content'>
            <div className='pic-name-wrapper'>
              <div className='name'>About Us</div>
            </div>
            <div className='desc'>
              <p>
                Welcome to Rocky Mountain Steam! We are a licensed and trusted provider of professional kitchen hood and exhaust cleaning services, dedicated to ensuring fire safety in commercial kitchens. With a keen focus on maintaining the highest standards, we meticulously clean and maintain kitchen hoods and exhaust systems in accordance with NFPA 96 guidelines. Our experienced team of certified technicians utilizes state-of-the-art equipment and techniques to eliminate grease buildup and ensure optimal performance of your kitchen ventilation system. We understand the critical importance of fire prevention and are committed to delivering exceptional service to our valued clients. At Rocky Mountain Steam, we take pride in our expertise, reliability, and dedication to safeguarding your kitchen&apos;s environment. Trust us to protect your business and keep your kitchen running smoothly. Contact us today to schedule a consultation and experience the peace of mind that comes with our professional cleaning services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
