import React from 'react'

const Footer = (): React.ReactElement => {
  return (
    <footer>
      <div className='container'>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='footer-col contact'>
            <h5>Contact</h5>
            <div>
              <div>rockymountainsteamlogan@gmail.com</div>
              <div>Text or call: (435) 610-9948</div>
            </div>
          </div>
          <div className='footer-col licenses'>
            <h5>Licenses</h5>
            <div>
              <div>Utah State Fire Marshal: K5311</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
