import React from 'react'
import ServiceImgCard from 'js/components/serviceimgcard'
import ServiceTextCard from 'js/components/servicetextcard'

import Backsplash from 'assets/Backsplash.jpg'
import Ducts from 'assets/Ducts.png'
import HoodSteam from 'assets/HoodSteam.jpg'
import VentFan from 'assets/VentFan.jpg'

const HoodCleaningServices = (): React.ReactElement => {
  return (
    <div className='service container'>
      <div className='row services-card-wrapper'>
        <ServiceTextCard className='col-12 col-md-6 col-lg-4'>
          <p>
            At Rocky Mountain Steam, we prioritize the significance of maintaining a safe working environment.  Our comprehensive cleaning services the entire Exhaust Hood System. You can trust our meticulous approach to keep your establishment running smoothly and your team and customers safe.
          </p>
        </ServiceTextCard>
        <ServiceImgCard title='Hood Steam Cleaning' img={HoodSteam} />
        <ServiceImgCard title='Exhaust Steam Cleaning' img={Ducts} />
        <ServiceImgCard
          title='Ventilation Fans Steam Cleaning'
          img={VentFan}
          containBackground
        />
        <ServiceImgCard title='Backsplash Steam Cleaning' img={Backsplash} />
        <ServiceTextCard className='col-12 col-md-6 col-lg-4'>
          <p>
            We go the extra mile to ensure that your restaurant maintains it high standards of cleanliness. We protect your kitchen from overspray and debris from the cleaning process. Our attention to detail will prevent the contamination of your kitchen and leave a clean environment for your staff and customers.
          </p>
        </ServiceTextCard>
      </div>
    </div>
  )
}

export default HoodCleaningServices
