import React from 'react'
import { Pages } from 'App'
import fullLogo from 'assets/logo_full.svg'

const Header = (props: {
  setPage: (p: Pages) => void
  currentPage: Pages
}): React.ReactElement => {
  const links = [
    { page: Pages.services, label: 'Services' },
    { page: Pages.estimate, label: 'Free Estimate' },
    { page: Pages.about, label: 'About Us' },
  ]

  return (
    <header>
      <div className='container d-flex justify-content-between flex-column flex-md-row'>
        <a
          onClick={(): void => props.setPage(Pages.services)}
          className='header-logo-wrap'
        >
          <img src={fullLogo} />
        </a>
        <nav>
          {links.map(({ page, label }) => (
            <a
              key={label.toLowerCase().replace(' ', '-')}
              className={props.currentPage === page ? 'active' : ''}
              onClick={(): void => props.setPage(page)}
            >
              {label}
            </a>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
